import React from "react";

export const PrivacyPolicyLink = () => {
  return (
    <a
      href="https://agileday.io/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy policy
    </a>
  );
};
