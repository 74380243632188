import React from "react";

function TenantSelection() {
  return (
    <div className="tenant-selection">
      <form action="/select-workspace" method="post">
        <div>
          <input
            type="text"
            name="workspace"
            placeholder="your-workspace"
            minLength={3}
            maxLength={20}
            // pattern="[a-z0-9-]+"
            required
          />
          <span>.agileday.io</span>
        </div>
        <div className="tenant-seleection-submit-wrapper">
          <input type="submit" value="Continue" />
        </div>
      </form>
    </div>
  );
}

export default TenantSelection;
