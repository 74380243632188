import * as firebaseui from "firebaseui";
import type { User, UserCredential } from "@firebase/auth-types";

class AgiledayAuthHandler extends firebaseui.auth.FirebaseUiHandler {
  constructor(
    element: Element | string,
    configs: { [key: string]: firebaseui.auth.CIAPHandlerConfig }
  ) {
    super(element, configs);
  }

  async startSignIn(
    auth: unknown,
    tenantInfo?: firebaseui.auth.SelectedTenantInfo
  ): Promise<UserCredential> {
    try {
      return (await super.startSignIn(auth, tenantInfo)) as UserCredential;
    } catch (error) {
      console.error("startSignIn :: error", error);
    }
  }

  async processUser(user: User): Promise<User> {
    try {
      return (await super.processUser(user)) as User;
    } catch (error) {
      console.error("processUser :: error", error);
      throw error;
    }
  }

  handleError(error: Error | firebaseui.auth.CIAPError): void {
    console.error("handleError ::", error);
    super.handleError(error);
  }
}

export default AgiledayAuthHandler;
