import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TenantLogin from "./TenantLogin";
import TenantSelection from "./TenantSelection";
import Logout from "./Logout";
import { PrivacyPolicyLink } from "./PrivacyPolicyLink";

const FIREBASE_APIKEY_COOKIE_NAME = "agileday-firebase-apikey";

const getApikey = () =>
  document.cookie
    .split(";")
    .map((c) => c.split("=", 2))
    .find(([name]) => name?.trim() === FIREBASE_APIKEY_COOKIE_NAME)?.[1];

function App() {
  const apiKey = getApikey();

  return (
    <div className="app">
      <div className="app-container">
        <main>
          <header className="app-header">
            <img src="/Agileday_logo_v2.svg" alt="Agileday logo" />
          </header>
          <Router>
            <Switch>
              <Route exact path="/">
                <TenantSelection />
              </Route>
              <Route exact path="/logout">
                <Logout apiKey={apiKey} />
              </Route>
              <Route path="/*">
                <TenantLogin apiKey={apiKey} />
              </Route>
            </Switch>
          </Router>
          <div className="questions-cta">
            Are you new to Agileday or have any questions?
            <br />
            <a href="https://www.agileday.com/contact-us">Contact us</a>
          </div>
        </main>
        <footer>
          <div className="contact-us">
            Agileday {new Date().getFullYear()}{" "}
            <span className="dot-separator">•</span>
            <PrivacyPolicyLink />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
